import React from 'react'
import styled from 'styled-components'
import WorksList from 'components/worksListFull'
import Layout from 'components/layout'

const WorksListWrapper = styled.div`
  max-width: 1200px;
  padding: 0 1rem;
  margin: 0 auto 4rem;
`

const SectionTitleWork = styled.h2`
  text-align: center;
  margin-bottom: 6rem;
  margin-top: 6rem;
`

const WorkPage = () => (
  <Layout>
    <SectionTitleWork>Selected work</SectionTitleWork>
    <WorksListWrapper>
      <WorksList />
    </WorksListWrapper>
  </Layout>
)

export default WorkPage
